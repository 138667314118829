"use client";

import React from "react";
import { useMemo, useCallback } from "react";
import { useLocalStorage } from "../hooks/use-local-storage";
import { SettingsContext } from "./settings-context";
import { SettingsValueProps } from "./types";
import { cartItemsProps } from "@/utils/types";
// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
  defaultSettings: SettingsValueProps;
};

export function SettingsProvider({ children, defaultSettings }: Props) {
  const [settings, setSettings] = useLocalStorage("settings", defaultSettings);

  const onUpdate = useCallback(
    (name: string, value: string | boolean | cartItemsProps[] ) => {
      setSettings((prevState: SettingsValueProps) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setSettings]
  );

  const memoizedValue = useMemo(
    () => ({
      ...settings,
      onUpdate,
    }),
    [settings, onUpdate]
  );

  return (
    <SettingsContext.Provider value={memoizedValue}>
      {children}
    </SettingsContext.Provider>
  );
}
