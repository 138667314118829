"use client";
import StarRatings from "react-star-ratings";

function StarRatingsFeature(props: any) {
  const { data } = props;
  return (
    <>
      <StarRatings
        rating={data || 0}
        starRatedColor="black"
        numberOfStars={5}
        starDimension="20px"
        starSpacing="1px"
        starHoverColor="black"
      />
    </>
  );
}

export default StarRatingsFeature;
