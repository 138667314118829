import { baseUrl, integrationBusCmsBaseUrl, ipUrl } from "@/utils/constant";
import {
  AddToCartPayload,
  CartListPayload,
  CategoryPayload,
  ExpertNetworkPayload,
  Payload,
  ProductsPayload,
  SuggestedExpertPayload,
  SuggestedSellerPayload,
  SupplierDetailsPayload,
  SupplierNetworkPayload,
  TopSellingProductsPayload,
} from "@/utils/types";
import { fetchApiData } from "./appService";
//unused
export const marketPlaceCardData = async (
  payload: TopSellingProductsPayload
) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/product/buyer/public/all`,
    "POST",
    payload
  );
  return result?.data;
};

//used,only base url, new working
export const fetchAllCategoryData = async () => {
  const result: any = await fetchApiData(
    `${baseUrl}/product-category/fetch-all`,
    "GET"
  );
  return result?.data;
};

export const fetchCountryCode = async () => {
  const result: any = await fetchApiData(`${baseUrl}/v2/country/all`, "GET");
  return result?.data;
};
//used,base url, new working
export const fetchTopBrands = async (data: any) => {
  const result: any = await fetchApiData(
    `${baseUrl}/productbrand/public/logo/all?limit=${data.limit}&recordCount=${data.recordCount}`,
    "GET"
  );
  return result;
};

//used , base url
export const fetchSupplierCategorys = async () => {
  const result: any = await fetchApiData(
    `${baseUrl}/category/fetchCategories`,
    "GET"
  );
  return result;
};

export const fetchProductSellerCount = async () => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/user/public/product-seller-counter?limit=8`,
    "GET"
  );
  return result;
};

//unused
export const fetchBrandByCategoryData = async (payload: ProductsPayload) => {
  const result: any = await fetchApiData(
    `${baseUrl}/productbrand/fetchBrandByCategory`,
    "POST",
    payload
  );
  return result?.data;
};
export const fetchProductSubCategoryData = async (payload: ProductsPayload) => {
  const result: any = await fetchApiData(
    `${baseUrl}/productSubCategory/fetchSubCategories`,
    "POST",
    payload
  );
  return result?.data;
};
//unused
export const fetchProductDetails = async (payload: any) => {
  const result: any = await fetchApiData(
    `${baseUrl}/product/public/fetchSellerProductById`,
    "POST",
    payload
  );
  return result;
};
//used, check at run time
export const fetchProductDetailSlug = async (slug: string) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/product/public/fetch-by-slug/${slug}`,
    "GET",
    "",
    true
  );
  return result;
};

export const fetchCoordinates = async () => {
  const result: any = await fetchApiData(`${ipUrl}`, "GET");
  return result;
};
export const fetchSupplierNetworkData = async (
  payload: SupplierNetworkPayload
) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/business-network/supplier/public/all`,
    "POST",
    payload,
    true
  );
  return result;
};
export const fetchBuyerNetworkData = async (
  payload: SupplierNetworkPayload
) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/business-network/buyer/public/all`,
    "POST",
    payload,
    true
  );
  return result;
};
export const fetchExpertNetworkData = async (payload: ExpertNetworkPayload) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/expert/public/all`,
    "POST",
    payload,
    true
  );
  return result;
};
export const fetchCategoryData = async (payload: CategoryPayload) => {
  const result: any = await fetchApiData(
    `${baseUrl}/industry/all`,
    "POST",
    payload
  );
  return result;
};

//unused
export const fetchSupplierDetails = async (payload: SupplierDetailsPayload) => {
  const result: any = await fetchApiData(
    `${baseUrl}/linked-supplier/public/supplier-detail`,
    "POST",
    payload
  );
  return result;
};

//used , check run time
export const fetchSupplierDetailsSlug = async (slug: string) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/user/public/fetch-by-slug/seller/${slug}`,
    "GET"
  );
  return result;
};
//used , check run time
export const fetchBuyerDetailsSlug = async (slug: string) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/user/public/fetch-by-slug/buyer/${slug}`,
    "GET"
  );
  return result;
};
//unused, check run time
export const fetchExpertsDetailsSlug = async (slug: string) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/user/public/fetch-by-slug/expert/${slug}`,
    "GET"
  );
  return result;
};
//unused
export const fetchSuggestedSellers = async (
  payload: SuggestedSellerPayload
) => {
  const result: any = await fetchApiData(
    `${baseUrl}/linked-supplier/public/fetchSuggestedSellers`,
    "POST",
    payload
  );
  return result;
};
//used ,check run time
export const fetchSuggestedSupplier = async (
  payload: SuggestedSellerPayload
) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/user/public/suggested/sellers`,
    "POST",
    payload
  );
  return result;
};
//used, check run time
export const fetchSuggestedBuyer = async (payload: SuggestedSellerPayload) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/user/public/suggested/buyers`,
    "POST",
    payload
  );
  return result;
};
//used,check run time
export const fetchSuggestedExperts = async (
  payload: SuggestedExpertPayload
) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/expert/public/all`,
    "POST",
    payload
  );
  return result;
};
//unused
export const addToCart = async (payload: AddToCartPayload) => {
  const result: any = await fetchApiData(
    `${baseUrl}/mycart/addToCartItem`,
    "POST",
    payload
  );
  return result;
};
//unused
export const fetchCartList = async (payload: CartListPayload) => {
  const result: any = await fetchApiData(
    `${baseUrl}/mycart/fetchCartList`,
    "POST",
    payload
  );
  return result;
};
//used,base url only
export const fetchElasticSearch = async (searchValue: string) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/elasticsearch/open-global-search/${searchValue}`,
    "GET"
  );
  return result;
};

export const fetchHeaderMenus = async (payload: any) => {
  const result: any = await fetchApiData(
    `${integrationBusCmsBaseUrl}cms/menu/fetch`,
    "post",
    payload
  );
  return result;
};

export const fetchSeoDetails = async (payload: any) => {
  const result: any = await fetchApiData(
    `${integrationBusCmsBaseUrl}cms/page/fetch`,
    "post",
    payload
  );
  return result;
};

export const fetchSubscriptionPlan = async (payload: any) => {
  const result: any = await fetchApiData(
    `${integrationBusCmsBaseUrl}cms/subscription/fetch`,
    "post",
    payload
  );
  return result;
};

export const fetchHomeContent = async (payload: Payload) => {
  const result: any = await fetchApiData(
    `${integrationBusCmsBaseUrl}cms/page/fetch`,
    "post",
    payload
  );
  return result.data;
};

export const fetchCommentContent = async (payload: any) => {
  const result: any = await fetchApiData(
    `${integrationBusCmsBaseUrl}cms/comment/fetch`,
    "post",
    payload
  );
  return result.data;
};
export const fetchBlogDetails = async (payload: any) => {
  const result: any = await fetchApiData(
    `${integrationBusCmsBaseUrl}cms/blog/fetch`,
    "post",
    payload
  );
  return result.data[0];
};
export const fetchBlogCategories = async () => {
  const result: any = await fetchApiData(
    `${integrationBusCmsBaseUrl}cms/blog-category/fetch`,
    "post",
    {
      projection: {
        populate: "deep",
      },
    }
  );
  return result.data;
};
//used,working
export const fetchPopularBuyers = async () => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/business-network/popular/buyers`,
    "GET"
  );
  return result.data;
};
//used,check run time
export const fetchPopularSuppliers = async () => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/business-network/popular/suppliers`,
    "GET"
  );
  return result.data;
};
//unused
export const fetchPopularExperts = async () => {
  const result: any = await fetchApiData(
    `${baseUrl}/business-network/popular/experts`,
    "GET"
  );
  return result.data;
};
//used,market place page  but not in sheet
export const fetchAllProducts = async (payload: ProductsPayload) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/product/public/all`,
    "POST",
    payload,
    true
  );
  return result;
};
//used,market place ,dublicate endpoint,base url change only
export const fetchProductCategory = async () => {
  const result: any = await fetchApiData(
    `${baseUrl}/product-category/fetch-all`,
    "GET"
  );
  return result.data;
};
//unused,checked
export const fetchPublicRfp = async () => {
  const result: any = await fetchApiData(
    `${baseUrl}/rfxorder/public/rfp`,
    "GET"
  );
  return result.data;
};
//used
export const fetchRfp = async () => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/rfx-order/public/open-orders-eauction`,
    "GET",
    "",
    true
  );
  return result;
};
//used,marketplace page checked ,dublicate
export const fetchSuggestedSellersAll = async (payload: any) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/user/public/suggested/sellers`,
    "POST",
    payload,
    true
  );
  return result.data;
};

export const fetchBlogs = async (payload: any) => {
  const result: any = await fetchApiData(
    `${integrationBusCmsBaseUrl}cms/blog/fetch`,
    "POST",
    payload
  );
  return result.data;
};
export const fetchLiveRateData = async (data: any) => {
  const result: any = await fetchApiData(
    `${baseUrl}/v2/currency-exchange/live-rate?source=SAR&currencies=${data}`,
    "GET"
  );
  return result;
};
