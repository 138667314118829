const AMAZONE_CLOUD_URL = "//d133th49upe1sl.cloudfront.net/images/";

export const image_path = AMAZONE_CLOUD_URL + "flags/1x1/";
export const default_supplier_logo =
  AMAZONE_CLOUD_URL + "business_logo_placeholder.jpg";
export const profile_pic = AMAZONE_CLOUD_URL + "theme/profile_pic.png";
export const app_store = AMAZONE_CLOUD_URL + "theme/app_store.png";
export const android_store = AMAZONE_CLOUD_URL + "theme/android_store.png";
export type CountryCodesProps = {
  country: string;
  value: string;
  label: string;
  country_id?: string;
  dialCode?: string;
  phoneLength: number | number[]
};

export const CountryCodes: Array<CountryCodesProps> = [
  {
      "country": "Afghanistan",
      "value": "+93",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'af.svg"/> +93',
      "phoneLength": 9
  },
  {
      "country": "Albania",
      "value": "+355",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'al.svg" /> +355',
      "phoneLength": 9
  },
  {
      "country": "Algeria",
      "value": "+213",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'dz.svg" /> +213',
      "phoneLength": 9
  },
  {
      "country": "American Samoa",
      "value": "+1-684",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'as.svg" /> +1-684',
      "phoneLength": 10
  },
  {
      "country": "Andorra",
      "value": "+376",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ad.svg" /> +376',
      "phoneLength": 6
  },
  {
      "country": "Angola",
      "value": "+244",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ao.svg" /> +244',
      "phoneLength": 9
  },
  {
      "country": "Anguilla",
      "value": "+1-264",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ai.svg" /> +1-264',
      "phoneLength": 10
  },
  {
      "country": "Antarctica",
      "value": "+672",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'aq.svg" /> +672',
      "phoneLength": 6
  },
  {
      "country": "Antigua and Barbuda",
      "value": "+1-268",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ag.svg" /> +1-268',
      "phoneLength": 10
  },
  {
      "country": "Argentina",
      "value": "+54",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ar.svg" /> +54',
      "phoneLength": [
          6,
          7,
          8
      ]
  },
  {
      "country": "Armenia",
      "value": "+374",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'am.svg" /> +374',
      "phoneLength": 6
  },
  {
      "country": "Aruba",
      "value": "+297",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'aw.svg" /> +297',
      "phoneLength": 7
  },
  {
      "country": "Australia",
      "value": "+61",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'au.svg" /> +61',
      "phoneLength": 9
  },
  {
      "country": "Austria",
      "value": "+43",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'at.svg" /> +43',
      "phoneLength": [
          10,
          11
      ]
  },
  {
      "country": "Azerbaijan",
      "value": "+994",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'az.svg" /> +994',
      "phoneLength": 9
  },
  {
      "country": "Bahamas",
      "value": "+1-242",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bs.svg" /> +1-242',
      "phoneLength": 10
  },
  {
      "country": "Bahrain",
      "value": "+973",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bh.svg" /> +973',
      "phoneLength": 8
  },
  {
      "country": "Bangladesh",
      "value": "+880",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bd.svg" /> +880',
      "phoneLength": 10
  },
  {
      "country": "Barbados",
      "value": "+1-246",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bb.svg" /> +1-246',
      "phoneLength": 10
  },
  {
      "country": "Belarus",
      "value": "+375",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'by.svg" /> +375',
      "phoneLength": 9
  },
  {
      "country": "Belgium",
      "value": "+32",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'be.svg" /> +32',
      "phoneLength": 9
  },
  {
      "country": "Belize",
      "value": "+501",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bz.svg" /> +501',
      "phoneLength": 7
  },
  {
      "country": "Benin",
      "value": "+229",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bj.svg" /> +229',
      "phoneLength": 8
  },
  {
      "country": "Bermuda",
      "value": "+1-441",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bm.svg" /> +1-441',
      "phoneLength": 10
  },
  {
      "country": "Bhutan",
      "value": "+975",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bt.svg" /> +975',
      "phoneLength": 7
  },
  {
      "country": "Bolivia",
      "value": "+591",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bo.svg" /> +591',
      "phoneLength": 9
  },
  {
      "country": "Bosnia and Herzegovina",
      "value": "+387",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ba.svg" /> +387',
      "phoneLength": 8
  },
  {
      "country": "Botswana",
      "value": "+267",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bw.svg" /> +267',
      "phoneLength": 7
  },
  {
      "country": "Brazil",
      "value": "+55",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'br.svg" /> +55',
      "phoneLength": 11
  },
  {
      "country": "British Indian Ocean Territory",
      "value": "+246",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'io.svg" /> +246',
      "phoneLength": 7
  },
  {
      "country": "British Virgin Islands",
      "value": "+1-284",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'vg.svg" /> +1-284',
      "phoneLength": 10
  },
  {
      "country": "Brunei",
      "value": "+673",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bn.svg" /> +673',
      "phoneLength": 7
  },
  {
      "country": "Bulgaria",
      "value": "+359",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bg.svg" /> +359',
      "phoneLength": 9
  },
  {
      "country": "Burkina Faso",
      "value": "+226",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bf.svg" /> +226',
      "phoneLength": 8
  },
  {
      "country": "Burundi",
      "value": "+257",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bi.svg" /> +257',
      "phoneLength": 8
  },
  {
      "country": "Cambodia",
      "value": "+855",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'kh.svg" /> +855',
      "phoneLength": 9
  },
  {
      "country": "Cameroon",
      "value": "+237",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cm.svg" /> +237',
      "phoneLength": 9
  },
  {
      "country": "Canada",
      "value": "+1",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ca.svg" /> +1',
      "phoneLength": 10
  },
  {
      "country": "Cape Verde",
      "value": "+238",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cv.svg" /> +238',
      "phoneLength": 7
  },
  {
      "country": "Cayman Islands",
      "value": "+1-345",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ky.svg" /> +1-345',
      "phoneLength": 10
  },
  {
      "country": "Central African Republic",
      "value": "+236",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cf.svg" /> +236',
      "phoneLength": 8
  },
  {
      "country": "Chad",
      "value": "+235",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'td.svg" /> +235',
      "phoneLength": 6
  },
  {
      "country": "Chile",
      "value": "+56",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cl.svg" /> +56',
      "phoneLength": 9
  },
  {
      "country": "China",
      "value": "+86",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cn.svg" /> +86',
      "phoneLength": 11
  },
  {
      "country": "Christmas Island",
      "value": "+61",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cx.svg" /> +61',
      "phoneLength": 9
  },
  {
      "country": "Cocos Islands",
      "value": "+61",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cc.svg" /> +61',
      "phoneLength": 9
  },
  {
      "country": "Colombia",
      "value": "+57",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'co.svg" /> +57',
      "phoneLength": 10
  },
  {
      "country": "Comoros",
      "value": "+269",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'km.svg" /> +269',
      "phoneLength": 7
  },
  {
      "country": "Cook Islands",
      "value": "+682",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ck.svg" /> +682',
      "phoneLength": 5
  },
  {
      "country": "Costa Rica",
      "value": "+506",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cr.svg" /> +506',
      "phoneLength": 8
  },
  {
      "country": "Croatia",
      "value": "+385",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'hr.svg" /> +385',
      "phoneLength": 9
  },
  {
      "country": "Cuba",
      "value": "+53",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cu.svg" /> +53',
      "phoneLength": 8
  },
  {
      "country": "Curacao",
      "value": "+599",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cw.svg" /> +599',
      "phoneLength": 7
  },
  {
      "country": "Cyprus",
      "value": "+357",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cy.svg" /> +357',
      "phoneLength": 8
  },
  {
      "country": "Czech Republic",
      "value": "+420",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cz.svg" /> +420',
      "phoneLength": 9
  },
  {
      "country": "Democratic Republic of the Congo",
      "value": "+243",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cd.svg" /> +243',
      "phoneLength": 7
  },
  {
      "country": "Denmark",
      "value": "+45",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'dk.svg" /> +45',
      "phoneLength": 8
  },
  {
      "country": "Djibouti",
      "value": "+253",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'dj.svg" /> +253',
      "phoneLength": 10
  },
  {
      "country": "Dominica",
      "value": "+1-767",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'dm.svg" /> +1-767',
      "phoneLength": 10
  },
  {
      "country": "Dominican Republic (1-809)",
      "value": "+1-809",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'do.svg" /> +1-809',
      "phoneLength": 10
  },
  {
      "country": "Dominican Republic (1-829)",
      "value": "+1-829",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'do.svg" /> +1-829',
      "phoneLength": 10
  },
  {
      "country": "Dominican Republic (1-849)",
      "value": "+1-849",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'do.svg" /> +1-849',
      "phoneLength": 10
  },
  {
      "country": "East Timor",
      "value": "+670",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'tl.svg" /> +670',
      "phoneLength": 7
  },
  {
      "country": "Ecuador",
      "value": "+593",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ec.svg" /> +593',
      "phoneLength": 9
  },
  {
      "country": "Egypt",
      "value": "+20",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'eg.svg" /> +20',
      "phoneLength": 10
  },
  {
      "country": "El Salvador",
      "value": "+503",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sv.svg" /> +503',
      "phoneLength": 8
  },
  {
      "country": "Equatorial Guinea",
      "value": "+240",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'gq.svg" /> +240',
      "phoneLength": 9
  },
  {
      "country": "Eritrea",
      "value": "+291",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'er.svg" /> +291',
      "phoneLength": 7
  },
  {
      "country": "Estonia",
      "value": "+372",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ee.svg" /> +372',
      "phoneLength": 8
  },
  {
      "country": "Ethiopia",
      "value": "+251",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'et.svg" /> +251',
      "phoneLength": 9
  },
  {
      "country": "Falkland Islands",
      "value": "+500",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'fk.svg" /> +500',
      "phoneLength": 5
  },
  {
      "country": "Faroe Islands",
      "value": "+298",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'fo.svg" /> +298',
      "phoneLength": 5
  },
  {
      "country": "Fiji",
      "value": "+679",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'fj.svg" /> +679',
      "phoneLength": 7
  },
  {
      "country": "Finland",
      "value": "+358",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'fi.svg" /> +358',
      "phoneLength": 12
  },
  {
      "country": "France",
      "value": "+33",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'fr.svg" /> +33',
      "phoneLength": 9
  },
  {
      "country": "French Polynesia",
      "value": "+689",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'pf.svg" /> +689',
      "phoneLength": 8
  },
  {
      "country": "Gabon",
      "value": "+241",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ga.svg" /> +241',
      "phoneLength": 7
  },
  {
      "country": "Gambia",
      "value": "+220",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'gm.svg" /> +220',
      "phoneLength": 7
  },
  {
      "country": "Georgia",
      "value": "+995",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ge.svg" /> +995',
      "phoneLength": 9
  },
  {
      "country": "Germany",
      "value": "+49",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'de.svg" /> +49',
      "phoneLength": 10
  },
  {
      "country": "Ghana",
      "value": "+233",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'gh.svg" /> +233',
      "phoneLength": 9
  },
  {
      "country": "Gibraltar",
      "value": "+350",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'gi.svg" /> +350',
      "phoneLength": 8
  },
  {
      "country": "Greece",
      "value": "+30",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'gr.svg" /> +30',
      "phoneLength": 10
  },
  {
      "country": "Greenland",
      "value": "+299",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'gl.svg" /> +299',
      "phoneLength": 6
  },
  {
      "country": "Grenada",
      "value": "+1-473",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'gd.svg" /> +1-473',
      "phoneLength": 10
  },
  {
      "country": "Guam",
      "value": "+1-671",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'gu.svg" /> +1-671',
      "phoneLength": 10
  },
  {
      "country": "Guatemala",
      "value": "+502",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'gt.svg" /> +502',
      "phoneLength": 8
  },
  {
      "country": "Guinea",
      "value": "+224",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'gn.svg" /> +224',
      "phoneLength": 9
  },
  {
      "country": "Guinea-Bissau",
      "value": "+245",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'gw.svg" /> +245',
      "phoneLength": 9
  },
  {
      "country": "Guyana",
      "value": "+592",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'gy.svg" /> +592',
      "phoneLength": 7
  },
  {
      "country": "Haiti",
      "value": "+509",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ht.svg" /> +509',
      "phoneLength": 8
  },
  {
      "country": "Honduras",
      "value": "+504",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'hn.svg" /> +504',
      "phoneLength": 8
  },
  {
      "country": "Hong Kong",
      "value": "+852",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'hk.svg" /> +852',
      "phoneLength": 8
  },
  {
      "country": "Hungary",
      "value": "+36",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'hu.svg" /> +36',
      "phoneLength": 9
  },
  {
      "country": "Iceland",
      "value": "+354",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'is.svg" /> +354',
      "phoneLength": 7
  },
  {
      "country": "India",
      "value": "+91",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'in.svg" /> +91',
      "phoneLength": 10
  },
  {
      "country": "Indonesia",
      "value": "+62",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'id.svg" /> +62',
      "phoneLength": 11
  },
  {
      "country": "Iran",
      "value": "+98",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ir.svg" /> +98',
      "phoneLength": 11
  },
  {
      "country": "Iraq",
      "value": "+964",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'iq.svg" /> +964',
      "phoneLength": 10
  },
  {
      "country": "Ireland",
      "value": "+353",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ie.svg" /> +353',
      "phoneLength": 9
  },
  {
      "country": "Israel",
      "value": "+972",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'il.svg" /> +972',
      "phoneLength": 9
  },
  {
      "country": "Italy",
      "value": "+39",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'it.svg" /> +39',
      "phoneLength": 10
  },
  {
      "country": "Ivory Coast",
      "value": "+225",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ci.svg" /> +225',
      "phoneLength": 8
  },
  {
      "country": "Jamaica",
      "value": "+1-876",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'jm.svg" /> +1-876',
      "phoneLength": 10
  },
  {
      "country": "Japan",
      "value": "+81",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'jp.svg" /> +81',
      "phoneLength": 13
  },
  {
      "country": "Jordan",
      "value": "+962",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'jo.svg" /> +962',
      "phoneLength": [
          8,
          9
      ]
  },
  {
      "country": "Kazakhstan",
      "value": "+7",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'kz.svg" /> +7',
      "phoneLength": 10
  },
  {
      "country": "Kenya",
      "value": "+254",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ke.svg" /> +254',
      "phoneLength": 10
  },
  {
      "country": "Kiribati",
      "value": "+686",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ki.svg" /> +686',
      "phoneLength": 8
  },
  {
      "country": "Kuwait",
      "value": "+965",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'kw.svg" /> +965',
      "phoneLength": 8
  },
  {
      "country": "Kyrgyzstan",
      "value": "+996",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'kg.svg" /> +996',
      "phoneLength": 9
  },
  {
      "country": "Laos",
      "value": "+856",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'la.svg" /> +856',
      "phoneLength": [
          8,
          9
      ]
  },
  {
      "country": "Latvia",
      "value": "+371",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'lv.svg" /> +371',
      "phoneLength": 8
  },
  {
      "country": "Lebanon",
      "value": "+961",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'lb.svg" /> +961',
      "phoneLength": [
          7,
          8
      ]
  },
  {
      "country": "Lesotho",
      "value": "+266",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ls.svg" /> +266',
      "phoneLength": 8
  },
  {
      "country": "Liberia",
      "value": "+231",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'lr.svg" /> +231',
      "phoneLength": [
          8,
          9
      ]
  },
  {
      "country": "Libya",
      "value": "+218",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ly.svg" /> +218',
      "phoneLength": 10
  },
  {
      "country": "Liechtenstein",
      "value": "+423",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'li.svg" /> +423',
      "phoneLength": 7
  },
  {
      "country": "Lithuania",
      "value": "+370",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'lt.svg" /> +370',
      "phoneLength": 8
  },
  {
      "country": "Luxembourg",
      "value": "+352",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'lu.svg" /> +352',
      "phoneLength": 9
  },
  {
      "country": "Macau",
      "value": "+853",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mo.svg" /> +853',
      "phoneLength": 8
  },
  {
      "country": "Macedonia",
      "value": "+389",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mk.svg" /> +389',
      "phoneLength": 8
  },
  {
      "country": "Madagascar",
      "value": "+261",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mg.svg" /> +261',
      "phoneLength": 7
  },
  {
      "country": "Malawi",
      "value": "+265",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mw.svg" /> +265',
      "phoneLength": [
          7,
          8,
          9
      ]
  },
  {
      "country": "Malaysia",
      "value": "+60",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'my.svg" /> +60',
      "phoneLength": 7
  },
  {
      "country": "Maldives",
      "value": "+960",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mv.svg" /> +960',
      "phoneLength": 7
  },
  {
      "country": "Mali",
      "value": "+223",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ml.svg" /> +223',
      "phoneLength": 8
  },
  {
      "country": "Malta",
      "value": "+356",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mt.svg" /> +356',
      "phoneLength": 8
  },
  {
      "country": "Marshall Islands",
      "value": "+692",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mh.svg" /> +692',
      "phoneLength": 7
  },
  {
      "country": "Mauritania",
      "value": "+222",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mr.svg" /> +222',
      "phoneLength": 8
  },
  {
      "country": "Mauritius",
      "value": "+230",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mu.svg" /> +230',
      "phoneLength": 8
  },
  {
      "country": "Mayotte",
      "value": "+262",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'yt.svg" /> +262',
      "phoneLength": 10
  },
  {
      "country": "Mexico",
      "value": "+52",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mx.svg" /> +52',
      "phoneLength": 10
  },
  {
      "country": "Micronesia",
      "value": "+691",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'fm.svg" /> +691',
      "phoneLength": 7
  },
  {
      "country": "Moldova",
      "value": "+373",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'md.svg" /> +373',
      "phoneLength": 8
  },
  {
      "country": "Monaco",
      "value": "+377",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mc.svg" /> +377',
      "phoneLength": 8
  },
  {
      "country": "Mongolia",
      "value": "+976",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mn.svg" /> +976',
      "phoneLength": 8
  },
  {
      "country": "Montenegro",
      "value": "+382",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'me.svg" /> +382',
      "phoneLength": 8
  },
  {
      "country": "Montserrat",
      "value": "+1-664",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ms.svg" /> +1-664',
      "phoneLength": 10
  },
  {
      "country": "Morocco",
      "value": "+212",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ma.svg" /> +212',
      "phoneLength": 9
  },
  {
      "country": "Mozambique",
      "value": "+258",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mz.svg" /> +258',
      "phoneLength": 12
  },
  {
      "country": "Myanmar",
      "value": "+95",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mm.svg" /> +95',
      "phoneLength": 9
  },
  {
      "country": "Namibia",
      "value": "+264",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'na.svg" /> +264',
      "phoneLength": 7
  },
  {
      "country": "Nauru",
      "value": "+674",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'nr.svg" /> +674',
      "phoneLength": 7
  },
  {
      "country": "Nepal",
      "value": "+977",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'np.svg" /> +977',
      "phoneLength": 10
  },
  {
      "country": "Netherlands",
      "value": "+31",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'nl.svg" /> +31',
      "phoneLength": 9
  },
  {
      "country": "New Caledonia",
      "value": "+687",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'nc.svg" /> +687',
      "phoneLength": 6
  },
  {
      "country": "New Zealand",
      "value": "+64",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'nz.svg" /> +64',
      "phoneLength": [
          8,
          9
      ]
  },
  {
      "country": "Nicaragua",
      "value": "+505",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ni.svg" /> +505',
      "phoneLength": 8
  },
  {
      "country": "Niger",
      "value": "+227",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ne.svg" /> +227',
      "phoneLength": 8
  },
  {
      "country": "Nigeria",
      "value": "+234",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ng.svg" /> +234',
      "phoneLength": 8
  },
  {
      "country": "Niue",
      "value": "+683",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'nu.svg" /> +683',
      "phoneLength": 4
  },
  {
      "country": "North Korea",
      "value": "+850",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'kp.svg" /> +850',
      "phoneLength": [
          4,
          6,
          7,
          13
      ]
  },
  {
      "country": "Northern Mariana Islands",
      "value": "+1-670",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mp.svg" /> +1-670',
      "phoneLength": 10
  },
  {
      "country": "Norway",
      "value": "+47",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'no.svg" /> +47',
      "phoneLength": 10
  },
  {
      "country": "Oman",
      "value": "+968",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'om.svg" /> +968',
      "phoneLength": 8
  },
  {
      "country": "Pakistan",
      "value": "+92",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'pk.svg" /> +92',
      "phoneLength": 10
  },
  {
      "country": "Palau",
      "value": "+680",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'pw.svg" /> +680',
      "phoneLength": 7
  },
  {
      "country": "Palestine",
      "value": "+970",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ps.svg" /> +970',
      "phoneLength": 9
  },
  {
      "country": "Panama",
      "value": "+507",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'pa.svg" /> +507',
      "phoneLength": 8
  },
  {
      "country": "Papua New Guinea",
      "value": "+675",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'pg.svg" /> +675',
      "phoneLength": 8
  },
  {
      "country": "Paraguay",
      "value": "+595",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'py.svg" /> +595',
      "phoneLength": 9
  },
  {
      "country": "Peru",
      "value": "+51",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'pe.svg" /> +51',
      "phoneLength": 9
  },
  {
      "country": "Philippines",
      "value": "+63",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ph.svg" /> +63',
      "phoneLength": 10
  },
  {
      "country": "Pitcairn",
      "value": "+64",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'pn.svg" /> +64',
      "phoneLength": [
          8,
          9
      ]
  },
  {
      "country": "Poland",
      "value": "+48",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'pl.svg" /> +48',
      "phoneLength": 9
  },
  {
      "country": "Portugal",
      "value": "+351",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'pt.svg" /> +351',
      "phoneLength": 9
  },
  {
      "country": "Puerto Rico (1-787)",
      "value": "+1-787",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'pr.svg" /> +1-787',
      "phoneLength": 10
  },
  {
      "country": "Puerto Rico (1-939)",
      "value": "+1-939",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'pr.svg" /> +1-939',
      "phoneLength": 10
  },
  {
      "country": "Qatar",
      "value": "+974",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'qa.svg" /> +974',
      "phoneLength": 8
  },
  {
      "country": "Republic of the Congo",
      "value": "+242",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'cg.svg" /> +242',
      "phoneLength": 9
  },
  {
      "country": "Reunion",
      "value": "+262",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 're.svg" /> +262',
      "phoneLength": 10
  },
  {
      "country": "Romania",
      "value": "+40",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ro.svg" /> +40',
      "phoneLength": 10
  },
  {
      "country": "Russia",
      "value": "+7",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ru.svg" /> +7',
      "phoneLength": 10
  },
  {
      "country": "Rwanda",
      "value": "+250",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'rw.svg" /> +250',
      "phoneLength": 9
  },
  {
      "country": "Saint Barthelemy",
      "value": "+590",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'bl.svg" /> +590',
      "phoneLength": 9
  },
  {
      "country": "Saint Helena",
      "value": "+290",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sh.svg" /> +290',
      "phoneLength": 4
  },
  {
      "country": "Saint Kitts and Nevis",
      "value": "+1-869",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'kn.svg" /> +1-869',
      "phoneLength": 10
  },
  {
      "country": "Saint Lucia",
      "value": "+1-758",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'lc.svg" /> +1-758',
      "phoneLength": 10
  },
  {
      "country": "Saint Martin",
      "value": "+590",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'mf.svg" /> +590',
      "phoneLength": 9
  },
  {
      "country": "Saint Pierre and Miquelon",
      "value": "+508",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'pm.svg" /> +508',
      "phoneLength": 6
  },
  {
      "country": "Saint Vincent and the Grenadines",
      "value": "+1-784",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'vc.svg" /> +1-784',
      "phoneLength": 10
  },
  {
      "country": "Samoa",
      "value": "+685",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ws.svg" /> +685',
      "phoneLength": [
          5,
          6,
          7
      ]
  },
  {
      "country": "San Marino",
      "value": "+378",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sm.svg" /> +378',
      "phoneLength": 10
  },
  {
      "country": "Sao Tome and Principe",
      "value": "+239",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'st.svg" /> +239',
      "phoneLength": 7
  },
  {
      "country": "Saudi Arabia",
      "value": "+966",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sa.svg" /> +966',
      "phoneLength": 9
  },
  {
      "country": "Senegal",
      "value": "+221",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sn.svg" /> +221',
      "phoneLength": 9
  },
  {
      "country": "Serbia",
      "value": "+381",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'rs.svg" /> +381',
      "phoneLength": 9
  },
  {
      "country": "Seychelles",
      "value": "+248",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sc.svg" /> +248',
      "phoneLength": 7
  },
  {
      "country": "Sierra Leone",
      "value": "+232",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sl.svg" /> +232',
      "phoneLength": 8
  },
  {
      "country": "Singapore",
      "value": "+65",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sg.svg" /> +65',
      "phoneLength": 8
  },
  {
      "country": "Sint Maarten",
      "value": "+1-721",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sx.svg" /> +1-721',
      "phoneLength": 10
  },
  {
      "country": "Slovakia",
      "value": "+421",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sk.svg" /> +421',
      "phoneLength": 9
  },
  {
      "country": "Slovenia",
      "value": "+386",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'si.svg" /> +386',
      "phoneLength": 9
  },
  {
      "country": "Solomon Islands",
      "value": "+677",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sb.svg" /> +677',
      "phoneLength": 7
  },
  {
      "country": "Somalia",
      "value": "+252",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'so.svg" /> +252',
      "phoneLength": [
          8,
          9
      ]
  },
  {
      "country": "South Africa",
      "value": "+27",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'za.svg" /> +27',
      "phoneLength": 9
  },
  {
      "country": "South Korea",
      "value": "+82",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'kr.svg" /> +82',
      "phoneLength": [
          7,
          8
      ]
  },
  {
      "country": "South Sudan",
      "value": "+211",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ss.svg" /> +211',
      "phoneLength": 7
  },
  {
      "country": "Spain",
      "value": "+34",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'es.svg" /> +34',
      "phoneLength": 9
  },
  {
      "country": "Sri Lanka",
      "value": "+94",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'lk.svg" /> +94',
      "phoneLength": 7
  },
  {
      "country": "Sudan",
      "value": "+249",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sd.svg" /> +249',
      "phoneLength": 7
  },
  {
      "country": "Suriname",
      "value": "+597",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sr.svg" /> +597',
      "phoneLength": [
          6,
          7
      ]
  },
  {
      "country": "Svalbard and Jan Mayen",
      "value": "+47",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sj.svg" /> +47',
      "phoneLength": 10
  },
  {
      "country": "Swaziland",
      "value": "+268",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sz.svg" /> +268',
      "phoneLength": 8
  },
  {
      "country": "Sweden",
      "value": "+46",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'se.svg" /> +46',
      "phoneLength": 7
  },
  {
      "country": "Switzerland",
      "value": "+41",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ch.svg" /> +41',
      "phoneLength": 9
  },
  {
      "country": "Syria",
      "value": "+963",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'sy.svg" /> +963',
      "phoneLength": 7
  },
  {
      "country": "Taiwan",
      "value": "+886",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'tw.svg" /> +886',
      "phoneLength": 9
  },
  {
      "country": "Tajikistan",
      "value": "+992",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'tj.svg" /> +992',
      "phoneLength": 9
  },
  {
      "country": "Tanzania",
      "value": "+255",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'tz.svg" /> +255',
      "phoneLength": 7
  },
  {
      "country": "Thailand",
      "value": "+66",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'th.svg" /> +66',
      "phoneLength": 9
  },
  {
      "country": "Togo",
      "value": "+228",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'tg.svg" /> +228',
      "phoneLength": 8
  },
  {
      "country": "Tokelau",
      "value": "+690",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'tk.svg" /> +690',
      "phoneLength": 5
  },
  {
      "country": "Tonga",
      "value": "+676",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'to.svg" /> +676',
      "phoneLength": 5
  },
  {
      "country": "Trinidad and Tobago",
      "value": "+1-868",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'tt.svg" /> +1-868',
      "phoneLength": 10
  },
  {
      "country": "Tunisia",
      "value": "+216",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'tn.svg" /> +216',
      "phoneLength": 8
  },
  {
      "country": "Turkey",
      "value": "+90",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'tr.svg" /> +90',
      "phoneLength": 11
  },
  {
      "country": "Turkmenistan",
      "value": "+993",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'tm.svg" /> +993',
      "phoneLength": 8
  },
  {
      "country": "Turks and Caicos Islands",
      "value": "+1-649",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'tc.svg" /> +1-649',
      "phoneLength": 10
  },
  {
      "country": "Tuvalu",
      "value": "+688",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'tv.svg" /> +688',
      "phoneLength": 5
  },
  {
      "country": "U.S. Virgin Islands",
      "value": "+1-340",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'vi.svg" /> +1-340',
      "phoneLength": 10
  },
  {
      "country": "Uganda",
      "value": "+256",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ug.svg" /> +256',
      "phoneLength": 7
  },
  {
      "country": "Ukraine",
      "value": "+380",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ua.svg" /> +380',
      "phoneLength": 9
  },
  {
      "country": "United Arab Emirates",
      "value": "+971",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ae.svg" /> +971',
      "phoneLength": 9
  },
  {
      "country": "United Kingdom",
      "value": "+44",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'gb.svg" /> +44',
      "phoneLength": 10
  },
  {
      "country": "United States",
      "value": "+1 ",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'us.svg" /> +1',
      "phoneLength": 10
  },
  {
      "country": "Uruguay",
      "value": "+598",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'uy.svg" /> +598',
      "phoneLength": 8
  },
  {
      "country": "Uzbekistan",
      "value": "+998",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'uz.svg" /> +998',
      "phoneLength": 9
  },
  {
      "country": "Vanuatu",
      "value": "+678",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'vu.svg" /> +678',
      "phoneLength": 5
  },
  {
      "country": "Vatican",
      "value": "+379",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'va.svg" /> +379',
      "phoneLength": 10
  },
  {
      "country": "Venezuela",
      "value": "+58",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 've.svg" /> +58',
      "phoneLength": 7
  },
  {
      "country": "Vietnam",
      "value": "+84",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'vn.svg" /> +84',
      "phoneLength": 9
  },
  {
      "country": "Wallis and Futuna",
      "value": "+681",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'wf.svg" /> +681',
      "phoneLength": 6
  },
  {
      "country": "Western Sahara",
      "value": "+212",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'eh.svg" /> +212',
      "phoneLength": 9
  },
  {
      "country": "Yemen",
      "value": "+967",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'ye.svg" /> +967',
      "phoneLength": 9
  },
  {
      "country": "Zambia",
      "value": "+260",
      "label": '<img loading="lazy" className="country-code-image" src="' + image_path + 'zm.svg" /> +260',
      "phoneLength": 9
  }
]
