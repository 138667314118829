export async function fetchApiData<T>(
  url: string,
  method: string = "GET",
  data?: any,
  errorStatus: boolean = false,
  customHeaders?: any
): Promise<T> {
  const headers = {
    "Content-Type": "application/json",
    ...customHeaders,
  };

  const requestOptions: RequestInit = {
    method,
    headers,
    body: data ? JSON.stringify(data) : undefined,
  };

  if (errorStatus) {
    const response = await fetch(url, requestOptions);
    let responseData: any = {};

    if (
      response.status === 200 &&
      response.headers.get("Content-Length") !== "0"
    ) {
      responseData = await response?.json();
    }
    responseData.status = response.status;
    return responseData;
  } else {
    try {
      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const responseData: T = await response.json();
      return responseData;
    } catch (error) {
      throw new Error(`API request failed: ${(error as Error).message}`);
    }
  }
}
