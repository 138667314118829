import React from "react";
import { Modal, Button } from "@/components/bootstrap";
import {
  AMAZONE_MEDIA_URL,
  MAINDOMAINURL,
  PROTOCOL,
  ThumbImageRatio,
  defaultproduct,
} from "@/utils/constant";
import { BsXLg } from "react-icons/bs";
import { useRouter } from "@/navigation";

const MarketplaceSignupModal = ({
  show,
  onHide,
  id,
  title,
  media_path,
  supplier_id,
  checkoutPage = false,
}: any) => {
  const router = useRouter();
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
               <div
          className="d-flex justify-content-end  align-items-center cursor-pointer pe-3 pt-2"
          onClick={onHide}
        >
          <BsXLg size={30} />
        </div>
        <div className="text-center py-5">
          {media_path && (
            <img
              loading="lazy"
              className="rounded-circle border mx-2 cursor-pointer"
              alt={title}
              src={
                media_path
                  ? AMAZONE_MEDIA_URL +
                    "User/" +
                    supplier_id +
                    "/Product/" +
                    id +
                    "/" +
                    ThumbImageRatio +
                    "/" +
                    media_path
                  : defaultproduct
              }
              width={84}
              height={84}
            />
          )}
          {checkoutPage ? (
            <h4 className="text-color-danger">{title}</h4>
          ) : (
            <>
              <h4 className="fw-bold my-2 supplier-content-color">To Add</h4>
              <h4 className="text-color-danger">{title}</h4>
            </>
          )}

          <div>
            <Button
              onClick={()=>router.push("/sign-in-by-email")}
              className="supplier-content-color sign-in-btn rounded-5 py-2 px-4 my-sm-2 mx-4"
            >
              Sign In
            </Button>
            <Button
              onClick={()=>router.push("/sign-in")}
              variant="secondary"
              className="text-color-white rounded-5 py-2 px-4 my-sm-2 sign-up-btn"
            >
              Sign up for free 
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MarketplaceSignupModal;
